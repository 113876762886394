<template>
	<section class="section">
		<grid-template cols="4">
			<template #title>
				<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`"/>
			</template>
			<product-card
				v-for="(card, id) in value.card_schema"
				:key="card.title"
				:card="{...card, id}"
				:prefix="prefix"
			/>
			<template #buttons>
				<cms-multi-section :value="`${prefix}.section_content`"/>
			</template>
		</grid-template>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import ProductCard from '../../components/molecules/cards/ProductCard/ProductCard.vue'

export default {
	components: {
		GridTemplate,
		BaseHeading,
		CmsMultiSection,
		ProductCard
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section {
	background-color: $white;
	padding: 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}
}
</style>
