<template>
	<base-link class="card" to="product" :title="`${product.name[this.$app.language]} - Oknar Kępno Sp. Z o.o.`" :params="{alias: product.alias}" v-animate="`opacity`">
		<div class="img-wrapper">
			<cms-image
				class="card-img"
				lazy
				:src="product.thumbnail"
				:base="{width: 300}"
				:lg="{width: 320, height: 290}"
			/>
			<cms-image
				class="card-hover"
				lazy
				:src="product.thumbnailHover"
				:base="{width: 360}"
				:lg="{width: 330, height: 272}"
			/>
			<div class="category-wrapper">
				<base-category-hover v-for="category in product.tags.categories" :key="category" :name="category"/>
			</div>
			<div class="feature-wrapper">
				<base-category-feature v-for="feature in product.tags.traits" :key="feature" :name="feature" />
			</div>
		</div>
		<div class="content-wrapper">
			<base-font
				class="card-title"
				variant="small-heading"
			>
			{{product.name[this.$app.language]}}
			</base-font>
			<base-font
				class="card-description"
				tag="div"
				size="xs"
				marginReset
				weight="regular"
				:html="product.description[this.$app.language]"
			/>
		</div>
	</base-link>
</template>
<script>
import BaseCategoryFeature from '../../BaseCategory/BaseCategoryFeature.vue'
import BaseCategoryHover from '../../BaseCategory/BaseCategoryHover.vue'
export default {
	components: {
		BaseCategoryHover,
		BaseCategoryFeature
	},
	props: {
		product: Object
	}
}
</script>
<style lang="scss" scoped>
.card {
	width: 100%;
	height: 100%;
	border: 1px solid $gray-300;
	background: $white;
    min-height: 405px;
    display: block;

	.card-img ::v-deep .base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
        min-height: 300px;
        @include media-breakpoint-up(xl) {
            min-height: auto;
        }
        @include media-breakpoint-up(xxl) {
            min-height: 300px;
        }
	}
	.card-logo {
		position: absolute;
		z-index: 4;
		top: 2rem;
		left: 1rem;
		@include media-breakpoint-up(xxl) {
			bottom: 10px;
			top: auto;
			left: auto;
			right: 10px;
		}
	}

	.content-wrapper {
		min-height: 13rem;
		padding: 15px;
	}
	.img-wrapper {
		position: relative;
		margin: 0;
		border-bottom: 4px solid $secondary;
		overflow: hidden;
		display: flex;
        justify-content: center;
        min-height: 300px;
	}
	.card-hover {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%,-50%);
		z-index: 3;
		opacity: 0;
		transition: opacity .3s ease-in;
       ::v-deep .base-image {
            height: 100%;
        }
	}
	@include media-breakpoint-up(xl) {
		&:hover {
			.card-hover {
				opacity: 1;
			}
		}
	}
	.feature-wrapper {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		z-index: 4;
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.category-wrapper {
		position: absolute;
		top: 1rem;
		right: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		gap: 5px;
	}
    ::v-deep &-description {
        text-transform: initial;
        line-height: 2.6rem;
        strong {
            color: initial;
        }
    }
}
</style>
