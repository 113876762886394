<template>
	<div class="category-hover">
		<div class="icon-wrapper" :class="name">
			<base-svg :name="name" />
		</div>
		<div class="text-wrapper">
			<base-font tag="span" size="xs" weight="regular" uppercase class="text-content" >{{name}}</base-font>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		name: String
	}
}
</script>
<style lang="scss" scoped>
	.category-hover {
        display: flex;
        align-items: center;
		gap: 10px;
        background: $white;
        border-radius: .2rem;
		box-shadow: 0 3px 20px rgba($black, .15);
		position: relative;
		z-index: 4;
		@include media-breakpoint-up(xl) {
			&:hover {
				.text-wrapper {
					width: 125px;
				}
			}
		}
		.icon-wrapper {
			width: 3rem;
			height: 3.8rem;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			border-radius: 5px 0 0 5px;
			display: grid;
			place-items: center;

			.svg-icon {
				width: 17px;
				height: 17px;
				::v-deep path {
					fill: $white;
				}
			}

			&.standard {
				background: $gray-400;
			}
			&.premium {
				background: $secondary-300;
			}
			&.exclusive {
				background: $yellow-500;
			}
		}
		.text-wrapper {
			width: 0;
			overflow: hidden;
			transition: .3s ease-in;
			display: flex;
			align-items: center;
			::v-deep .text-content {
				padding: 1rem 4rem 1rem 1rem;
			}
		}
	}
</style>
