<template>
	<div class="feature-hover" @mouseenter="addWidth" @mouseleave="removeWidth" :class="type">
		<div class="icon-wrapper" :class="name">
			<base-svg :name="chooseIcon(name)" />
		</div>
		<div class="text-wrapper" :style="`width:${width}px`">
			<base-font tag="span" size="xs" weight="regular" uppercase class="text-content" ref="widthElement">{{lang[name]}}</base-font>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		name: String,
		type: {
			type: String,
			default: null
		}
	},
	data () {
		return {
			width: this.type ? 50 : 30
		}
	},
	methods: {
		chooseIcon (category) {
			return {
				powerSaving: 'eco',
				securityAndHealth: 'safety',
				design: 'design_palette',
				standard: 'standard',
				premium: 'premium',
				exclusive: 'exclusive'
			}[category]
		},
		addWidth () {
			this.width = this.getWidth
		},
		removeWidth () {
			this.width = this.type ? 50 : 30
		}
	},
	computed: {
		getWidth () {
			return this.$refs.widthElement.offsetWidth
		},
		lang () {
			return this.$app.translator.get('products')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>
<style lang="scss" scoped>
	.feature-hover {
        display: flex;
        align-items: center;
		gap: 10px;
        background: $white;
        border-radius: .2rem;
		box-shadow: 0 0 6px rgba($black, .15);
		position: relative;
		z-index: 4;
		@include media-breakpoint-up(xl) {
			&:hover {
				.text-wrapper {
					width: 200px;
				}
			}
		}
		.icon-wrapper {
			width: 3rem;
			height: 3rem;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			border-radius: 5px;
			display: grid;
			place-items: center;

			.svg-icon {
				width: 12px;
				height: 12px;
			}

			&.standard {
				background: $gray-500;
			}
			&.premium {
				background: $secondary-300;
			}
			&.exclusive {
				background: $yellow-500;
			}
		}
		.text-wrapper {
			overflow: hidden;
			transition: .3s ease-in;
			display: flex;
			align-items: center;
			::v-deep .text-content {
				font-size: 10px;
				padding: 6px 6px 6px 36px;
				gap: 6px;
				white-space: nowrap;
			}
		}
		&.product {
			.icon-wrapper {
				width: 5rem;
				height: 5rem;
				background: $white;

				&.standard ::v-deep path {
					fill: $gray-400;
				}
				&.premium ::v-deep path  {
					fill: $secondary-300;
				}
				&.exclusive ::v-deep path {
					fill: $yellow-500;;
				}
			}

			::v-deep .svg-icon {
				height: 2rem;
				width: 2rem;
			}

			::v-deep .text-content {
				font-size: 14px;
				padding: 17px 17px 17px 55px;
				height: 50px;
				gap: 6px;
				white-space: nowrap;
				align-items: baseline;
			}
		}

	}
</style>
